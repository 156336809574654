.stepper {
	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-grid;
	grid-auto-flow: column;
	overflow: hidden;
	overflow-x: auto;
	counter-reset: step;
	grid-auto-columns: 1fr;
	text-align: center;

	&__container {
		display: flex;
		justify-content: center;
	}

	&__vertical {
		grid-auto-rows: 1fr;
		grid-auto-flow: row;
	}
}

.step__title {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 2.25rem;
	line-height: 2.5rem;
}

.title__icon {
	margin: 0 5px;
	height: 34px;
}

.stepper .step {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	grid-template-columns: auto;
	grid-template-rows: repeat(2, minmax(0, 1fr));
	grid-template-rows: 40px 1fr;
	place-items: center;
	text-align: center;
	min-width: 4rem;
}

.stepper__vertical .step {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: repeat(1, minmax(0, 1fr));
	gap: 0.5rem;
	grid-template-columns: 40px 1fr;
	grid-template-rows: auto;
	min-height: 4rem;
	justify-items: start;
}

.stepper__vertical .step {
	width: 100%;
	gap: 0.5rem;
	grid-template-columns: 40px 1fr;
	grid-template-rows: auto;
	min-height: 4rem;
	justify-items: start;
}

.step__container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 15px;
	margin: 1rem;
	width: 90%;
	border: 3px solid white;
	border-radius: 12px;
}

.stepper .step + .step:before,
.stepper .step:after {
	opacity: 1;
	background-color: white;
	color: #282828;
}

.stepper .step:after {
	content: counter(step);
	counter-increment: step;
	z-index: 1;
	position: relative;
	grid-column-start: 1;
	grid-row-start: 1;
	display: grid;
	height: 2rem;
	width: 2rem;
	place-items: center;
	place-self: center;
	border-radius: 9999px;
}

.stepper__vertical .step:before {
	height: 100%;
	width: 0.5rem;
	--tw-translate-x: -50%;
	--tw-translate-y: -50%;
	transform: translate(100%, -35px) rotate(90deg);
	margin-left: 50%;
}

.stepper .step:before {
	top: 0;
	grid-column-start: 1;
	grid-row-start: 1;
	height: 100%;
	width: 6px;
	transform: translateY(-49%);
	content: '';
	margin-left: 17px;
}

.stepper .step:first-child:before {
	content: none;
}

.stepper .step + .step:before,
.stepper .step:after {
	opacity: 1;
	background-color: white;
	color: #282828;
}

.countdown__container {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 50px;
	font-size: 20px;
}

.pump__button {
	min-width: 250px;
	font-size: 30px;
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 50px;
	align-items: center;
}

@media (min-width: 600px) {
	.countdown__container {
		font-size: 40px;
	}
}

@media (min-width: 1024px) {
	.stepper__horizontal {
		grid-auto-columns: 1fr;
		display: inline-grid;
		grid-auto-flow: column;
		overflow: hidden;
		overflow-x: auto;
	}

	.stepper__horizontal .step {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
		place-items: center;
		text-align: center;
		grid-template-rows: 40px 1fr;
		grid-template-columns: auto;
		min-width: 4rem;
	}

	.stepper__horizontal .step:before {
		height: 0.5rem;
		width: 90%;
		content: '';
		margin-top: 8px;
		margin-left: -100%;
	}
}
