a {
  cursor: pointer;
}

.icon {
  width: 22px;
}

.icon__2 {
  width: 26px;
}

.icon__3 {
  width: 32px;
}

.primary-header {
  z-index: 5;
  position: relative;
}

.flex-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}

.primary-header {
  padding-block: 2rem;
}

.primary-header__inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 32px 0 32px;
}

.primary-nav {
  min-width: 50%;
}

.primary-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.primary-nav a {
  color: white;
  font-weight: 700;
  text-decoration: none;
}

/* NavBar */

.navbar2 {
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar2 .nav-container li {
  list-style: none;
}

.navbar2 .nav-container a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar2 .nav-container a:hover {
  font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
  height: 32px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 19px;
  width: 26px;
  top: 5px;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: white;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar2 .menu-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-top: 120px;
  top: -37px;
  left: calc(-100vw + 56px);
  height: 743px;
  width: 110vw;
  z-index: 1;
  background-color: #3b3b3b;
  opacity: 0.9;
  transform: translate(-150%);
  transition: transform 0.5s ease-in-out;
  text-align: center;
}

.navbar2 .menu-items li {
  font-family: "Rubik", sans-serif;
  font-size: 2.5rem;
  color: white;
  font-weight: 800;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(42deg);
  background: white;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-40deg);
  background: white;
}

.nav-container input[type="checkbox"]:checked ~ .logo {
  display: none;
}

@media (min-width: 900px) {
  .icon {
    width: 32px;
  }

  .icon__2 {
    width: 32px;
  }

  .icon__3 {
    width: 40px;
  }

  .flex-group {
    gap: 1.5rem;
  }
}
