@import url("https://fonts.googleapis.com/css2?family=EB+Garamond");
@import url("https://fonts.cdnfonts.com/css/handwriting");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "";
  font-family: "Rubik", sans-serif;
}

html {
  color-scheme: dark;
}

body {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700; /* for demo only */
}

.main-content {
  position: relative;
  background: #282828; /*#5b534d*/
  padding-block: 6rem;
  min-height: 100vh;
}

.title__text {
  font-size: 50px;
  margin: 80px 0 80px 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

img {
  display: block;
  max-width: 100%;
}

.wrapper {
  width: min(65rem, 100% - 4rem);
  margin-inline: auto;
}

.hide__sm {
  display: none;
}

@media (min-width: 900px) {
  .hide__md {
    display: none;
  }

  .hide__sm {
    display: block;
  }
}

@media (min-width: 1265px) {
  .flex-group {
    width: 50%;
  }

  .parallax__pinny {
    top: 39vh;
    width: 25%;
    width: 350px;
  }
}
