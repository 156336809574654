.tokenomics {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    border: 3px solid white;
    border-radius: 12px;
    max-width: 650px;
    font-size: 1.5rem;
    text-align: center;
  }

  &__pump {
    margin: 0 5px;
    height: 25px;
  }
}
