.meme {
  &__title {
    margin-top: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__previewer {
    position: relative;
    width: 500px;
    border: 3px solid white;
    height: 500px;
    border-radius: 30px;

    & img {
      position: absolute;
    }
  }

  &__bg {
    border-radius: 30px;
  }

  &__part {
    transform: scale(0.7);
  }

  &__text {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__container {
    width: 80%;
  }
}

.buttons__container {
  margin-top: 20px;
}

.glass__button {
  display: inline-block;
  padding: 15px;
  border: 0;
  text-decoration: none;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 6px;

  & img {
    height: 32px;
  }
}

.glass__button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.glass__button:first-child {
  margin-left: 0;
}

.glass__button:last-child {
  margin-right: 0;
}

@media (max-width: 600px) {
  .meme {
    &__previewer {
      width: 350px;
      height: 350px;
    }
  }
}
